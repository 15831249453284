import React, { useState, useRef, useEffect, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import $ from 'jquery';

// Imports React bootstrap
import Tooltip from "@mui/material/Tooltip";
import Form from 'react-bootstrap/Form';
import { Accordion, Modal, Table } from "react-bootstrap";

// Material Dashboard 2 PRO React components
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { FiDownload } from "react-icons/fi";

// React icons
import { MdDelete } from "react-icons/md";

//File Imports
import FrmValidations from "FrmGeneric/FrmValidations";
import SuccessModal from "components/Modals/SuccessModal";
import ErrorModal from "components/Modals/ErrorModal"
import ComboBox from "Features/ComboBox";
import { globalQuery, resetGlobalQuery } from "assets/Constants/config-constant"
import ConfigConstants from "assets/Constants/config-constant";
import GenerateTAutoNo from "FrmGeneric/GenerateTAutoNo";
import ExcelExport from "Features/Exports/ExcelExport";
import FrmMEmployeesEntry from "Masters/MEmployee/MEmployeeEntry/components/FrmMEmployeesEntry";
import { CircularProgress } from "@material-ui/core";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import ValidateNumberDateInput from "FrmGeneric/ValidateNumberDateInput";
import ConfirmationModal from "components/Modals/ConfirmationModal";
import FrmDepartmentEntry from "Masters/MDepartment/FrmDepartmentEntry";
import FrmMProductTypeEntry from "Masters/MProductType/FrmProductEntry";
import MCostCenterEntry from "Masters/MFMCostCenter/MCostCenterEntry/Index";

// Import for the searchable combo box.
import Select from 'react-select';
import { renderToString } from "react-dom/server";

export default function FrmRawMaterialReturnEntry() {
    const configConstants = ConfigConstants();
    const { FINANCIAL_SHORT_NAME, COMPANY_ID, COMPANY_BRANCH_ID, UserId, UserName, DEPARTMENT_ID } = configConstants;

    const { state } = useLocation();
    const { idList, keyForViewUpdate, compType, modules_forms_id, requestfor } = state || {};
    const [isLoading, setIsLoading] = useState(false);
    const [IsView, setIsView] = useState(false)
    const [openIssueDetailsAccord, setOpenIssueDetailsAccord] = useState(true);

    // For navigate
    const navigate = useNavigate();

    // UseRefs
    const validate = useRef();
    const comboDataAPiCall = useRef();
    const generateAutoNoAPiCall = useRef();
    const exlsExp = useRef();
    const validateNumberDateInput = useRef();
    const issueNoComboRef = useRef();
    const materialsComboRef = useRef();
    const lotNoComboRef = useRef();
    let returnByComboRef = useRef(null);

    //combo options
    const [materialtypeOptions, setIssueReturnTypeIdOptions] = useState([])
    const [departmentIdOPtions, setDepartmentIdOptions] = useState([])
    const [subDepartmentOPtions, setSubDepartmentOptions] = useState([])
    const [materialOptions, setMaterialOptions] = useState([])
    const [lotNoOptions, setLotNoOptions] = useState([])
    const [returnOptions, setReturnOptions] = useState([])
    const [employeeOptions, setEmployeeOptions] = useState([])

    const [materialIssueDetailsData, setMaterialIssueDetailsData] = useState([])
    const [materialReturnMasterData, setMaterialReturnMasterData] = useState([])
    const [issueNoOptions, setIssueNoOptions] = useState([]);
    const [cmb_issue_no, setIssueNo] = useState('');
    const [cmb_product_material_id, setProductMaterialId] = useState('');
    const [cmb_lot_no, setLotNo] = useState('');


    // for today date formatted
    const todayDate = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    //Form Fields
    const [issue_return_master_transaction_id, setIssueReturnMasterTransactionId] = useState(0);
    const [cmb_issue_group_type, setIssue_group_type] = useState('');
    const [cmb_indent_issue_return_type_id, setIssueReturnTypeId] = useState('');
    const [txt_issue_return_no, setIssueReturnNo] = useState(0);
    const [cmb_department, setDepartmentId] = useState(keyForViewUpdate === "" ? DEPARTMENT_ID : "");
    const [cmb_sub_department, setSubDepartmentId] = useState('')
    const [cmb_return_by, setReturnBy] = useState(UserId);
    const [cmb_received_by_id, setReceivedById] = useState('');
    const [txt_remark, setRemark] = useState('');
    const [chk_isactive, setIsActive] = useState(true);
    const [cmb_return_status, setReturnStatus] = useState("P");


    const currentDate = new Date().toISOString().split('T')[0];

    const [dt_issue_date, setIssueDate] = useState();
    const [dt_return_date, setReturnDate] = useState(todayDate);
    const [dt_material_issue_date, setmaterialissueDate] = useState(todayDate);
    const [dt_received_date, setReceivedDate] = useState("");


    useEffect(async () => {
        setIsLoading(true);
        try {
            FnSetFieldUserAccess();
            await fillComobos();
            if (idList !== "") {
                await FnCheckUpdateResponce();
            }
            FnSetFieldUserAccess();
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
        // Close indent order no dropdown 
        $(document).on('mouseup', function (e) {
            var container = $("#indent-order-ul");
            if (!container.is(e.target) && container.has(e.target).length === 0) {
                container.hide();
            }
        });
        // Close customer order no dropdown 
        $(document).on('mouseup', function (e) {
            var container = $(".erp_dropdown-content");
            if (!container.is(e.target) && container.has(e.target).length === 0) {
                container.hide();
            }
        });
    }, [])


    //Error Msg
    const handleCloseErrModal = () => setShowErrorMsgModal(false);
    const [showErrorMsgModal, setShowErrorMsgModal] = useState(false);
    const [errMsg, setErrMsg] = useState('');
    // Confirmation Modal fields
    const [showConfirmationModal, setShowConfiemationModal] = useState(false)

    const [messageForConfirmation, setMessageForConfirmation] = useState(false)
    const closeConfirmationModal = async (key) => {
        switch (key) {
            case 'Exit':
                setShowSuccessMsgModal(false);
                break;
            default:
                break;
        }
        moveToListing();
    }

    const [showAddRecIndentIssueModal, setshowAddRecIndentIssueModal] = useState(false);
    const [modalHeaderName, setHeaderName] = useState('')

    const [showSuccessMsgModal, setShowSuccessMsgModal] = useState(false);
    const [succMsg, setSuccMsg] = useState('');
    const [modalOrderDetails, setModalOrderDetails] = useState('');

    // Show ADD record Modals
    const closeRecordAddIndentIssueModal = async () => {
        switch (modalHeaderName) {
            case 'Requested By':
                comboOnChange('departmentId')
                break;
            case 'Department':
                resetGlobalQuery();
                globalQuery.columns.push("field_id");
                globalQuery.columns.push("field_name");
                globalQuery.table = "cmv_department";
                globalQuery.conditions.push({ field: "department_type", operator: "=", value: 'M' });
                globalQuery.conditions.push({ field: "company_id", operator: "IN", values: [0, COMPANY_ID] });
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                const departmentIdApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
                setDepartmentIdOptions(departmentIdApiCall)
                break;

            case 'Material Type':
                resetGlobalQuery();
                globalQuery.columns.push("field_id");
                globalQuery.columns.push("field_name");
                globalQuery.columns.push("product_type_short_name");
                globalQuery.table = "smv_product_type"
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                globalQuery.conditions.push({ field: "product_type_group", operator: "IN", values: ["RM", "FG"] });
                comboDataAPiCall.current.fillFiltersCombo(globalQuery)
                    .then(getProductType => {
                        setIssueReturnTypeIdOptions(getProductType)
                        console.log("Product Type List: ", getProductType);
                    });

                break;
            case 'Sub Department':
                resetGlobalQuery();
                globalQuery.columns.push("field_id");
                globalQuery.columns.push("field_name");
                globalQuery.table = "cmv_department"
                globalQuery.conditions.push({ field: "department_type", operator: "=", value: 'S' });
                globalQuery.conditions.push({ field: "parent_department_id", operator: "=", value: cmb_department });
                globalQuery.conditions.push({ field: "company_id", operator: "IN", values: [0, COMPANY_ID] });
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                const subDepartmentApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
                setSubDepartmentOptions(subDepartmentApiCall)
                break;



            default:
                break;
        }
        setshowAddRecIndentIssueModal(false);

    }



    // -------------------------------End Common terms -------------------------------------------------------------------------

    const fillComobos = async () => {
        try {

            resetGlobalQuery();
            globalQuery.columns.push("field_id");
            globalQuery.columns.push("field_name");
            globalQuery.columns.push("product_type_short_name");
            globalQuery.table = "smv_product_type";
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            comboDataAPiCall.current.fillFiltersCombo(globalQuery)
                .then(getProductType => {
                    setIssueReturnTypeIdOptions(getProductType)
                });
            if (keyForViewUpdate === '') {
                setIssueReturnTypeId('12');
                await comboOnChange('materialTypeId');
            }


            // const getdepartmentId = await comboDataAPiCall.current.fillMasterData("cmv_department", "", "");
            resetGlobalQuery();
            globalQuery.columns.push("field_id");
            globalQuery.columns.push("field_name");
            globalQuery.table = "cmv_department";
            globalQuery.conditions.push({ field: "department_type", operator: "=", value: 'M' });
            globalQuery.conditions.push({ field: "company_id", operator: "IN", values: [0, COMPANY_ID] });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            const getdepartmentId = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
            setDepartmentIdOptions(getdepartmentId)
            if (keyForViewUpdate === '') {
                await comboOnChange('departmentId');
            }

            resetGlobalQuery();
            globalQuery.columns.push("field_id");
            globalQuery.columns.push("field_name");
            globalQuery.table = "cmv_employee";
            globalQuery.conditions.push({ field: "employee_type", operator: "!=", value: "Worker" });
            globalQuery.conditions.push({ field: "is_active", operator: "=", value: 1 });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            const getEmployee = await comboDataAPiCall.current.fillFiltersCombo(globalQuery)
            setEmployeeOptions(getEmployee)


            //get Return By Options
            resetGlobalQuery();
            globalQuery.columns = ["field_id", "field_name"];
            globalQuery.table = "cmv_employee";
            globalQuery.conditions.push({ field: "is_active", operator: "=", value: 1 });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            globalQuery.conditions.push({ field: "employee_type", operator: "!=", value: "Worker" });
            const returnbyIdApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
            const returnByOpts = [
                { value: '', label: 'Select', },
                ...returnbyIdApiCall.map((empl) => ({ ...empl, value: empl.field_id, label: empl.field_name })),
            ];
            setReturnOptions(returnByOpts);

        } catch (error) {
            console.log("error: ", error)
        }
    }
    const FnSetFieldUserAccess = () => {
        if (keyForViewUpdate !== '') {
            var saveBtn = document.getElementById('save-btn');
            switch (keyForViewUpdate) {
                case 'view':
                    $('input[type="checkbox"]').prop('readonly', true);
                    setIsView(true)
                    $('#submain_heading').append('');
                    $('#submain_heading').text('(View)');
                    $("input[type=radio]").attr('disabled', true);
                    $("form").find("input,textarea,select").attr("disabled", "disabled");
                    $("table").find("input,button,textarea,select").attr("disabled", "disabled");
                    $('#clearbutton-id').hide();
                    $('#save-btn').hide();
                    $('#cmb_customer_order_no').attr('disabled', true);
                    $('#add-material').attr('disabled', 'disabled');
                    $("table").find('input[id^="indent_quantity_"]').attr("disabled", "disabled");
                    break;

                case 'update':
                    $('#submain_heading').append('');
                    $('#cmb_indent_issue_return_type_id').attr('disabled', true);
                    $('#submain_heading').text('(Modification)');
                    $('#txt_indent_date').attr('disabled', true);
                    $('#cmb_customer_order_no').attr('disabled', false);
                    $('#cmb_customer').attr('disabled', true);
                    $('#clearbutton-id').hide();
                    saveBtn.textContent = 'Update';
                    break;

                case 'Receive':
                    $('input[type="checkbox"]').prop('readonly', true);
                    setIsView(true)
                    $('#submain_heading').append('');
                    $('#submain_heading').text('(Receive)');
                    saveBtn.textContent = 'Receive';
                    $("form").find("input,textarea,select").attr("disabled", "disabled");
                    $("form").find('textarea#txt_remark').removeAttr("disabled");
                    $('table .approveField').removeAttr("disabled");
                    $('#clearbutton-id').hide();
                    $('#viewdocument-id').hide();
                    break;
            }
        }
    }


    const FnCheckUpdateResponce = async () => {
        try {
            if (idList !== '') {
                const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/StRawMaterialReturnMaster/FnShowRawMaterialReturnDetails/${COMPANY_ID}?issue_return_no=${idList.issue_return_no}`)
                const response = await apiCall.json();
                let getRawMaterialReturnData = response.rawMaterialReturnMasterRecord;

                if (getRawMaterialReturnData !== null && getRawMaterialReturnData !== "") {
                    setMaterialReturnMasterData(getRawMaterialReturnData)
                    setIssueReturnMasterTransactionId(getRawMaterialReturnData.issue_return_master_transaction_id)
                    setIssueReturnTypeId(getRawMaterialReturnData.indent_issue_return_type_id)
                    setIssueReturnNo(getRawMaterialReturnData.issue_return_no)
                    setReturnDate(getRawMaterialReturnData.issue_return_date)
                    setDepartmentId(getRawMaterialReturnData.department_id)
                    await comboOnChange('departmentId')
                    setSubDepartmentId(getRawMaterialReturnData.sub_department_id)
                    await comboOnChange('subDepartment');
                    setIssueNo(getRawMaterialReturnData.issue_no)
                    setIssueDate(getRawMaterialReturnData.issue_date)
                    setReturnBy(getRawMaterialReturnData.return_by_id)
                    setRemark(getRawMaterialReturnData.remark)
                    setIsActive(getRawMaterialReturnData.is_active)
                    setReturnStatus(getRawMaterialReturnData.issue_return_status); //approve status while approve


                    if (keyForViewUpdate === 'Receive' || getRawMaterialReturnData.issue_return_status === 'R') {
                        setReturnStatus('R')
                        setReceivedById(UserId)
                        setReceivedDate(todayDate)
                    }

                    setMaterialIssueDetailsData(response.rawMaterialDetailsRecords)
                }
            }
        } catch (error) {
            console.log("error: ", error)
        }
    }


    const FnLoadIssueNos = async (subDepartmentId) => {
        try {
            // GET ISSUE NO's 
            resetGlobalQuery();
            globalQuery.columns = ["issue_no", "issue_date", "set_no"];
            globalQuery.table = "st_indent_material_issue_master";
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            globalQuery.conditions.push({ field: "indent_issue_type_id", operator: "=", value: 12 });
            globalQuery.conditions.push({ field: "sub_department_id", operator: "=", value: subDepartmentId });
            globalQuery.conditions.push({ field: "issue_status", operator: "IN", values: ['MI'] });

            comboDataAPiCall.current.fillFiltersCombo(globalQuery)
                .then(getIssueNos => {
                    const issueNoOpts = [
                        { value: '', label: 'Select', },
                        ...getIssueNos.map((issueNo) => ({
                            ...issueNo,
                            value: issueNo.issue_no,
                            label: `${issueNo.issue_no}${issueNo.set_no ? ` [${issueNo.set_no}]` : ''}`,
                            issue_date: issueNo.issue_date,
                            set_no: issueNo.set_no,
                        })),
                    ];
                    setIssueNoOptions(issueNoOpts)
                });

        } catch (error) {
            console.log(error);
        }
    }

    const FnLoadMaterialList = async () => {
        try {
            // GET ISSUE NO's 
            resetGlobalQuery();
            globalQuery.columns = ["st.product_material_id", "sm.product_rm_name AS product_material_name"];
            globalQuery.table = "st_indent_material_issue_details st";
            globalQuery.conditions.push({ field: "st.is_delete", operator: "=", value: 0 });

            globalQuery.joins = [
                {
                    "table": "sm_product_rm sm",
                    "type": "inner",
                    "on": [{
                        "left": "sm.product_rm_id",
                        "right": "st.product_material_id"
                    },
                    {
                        "left": "sm.product_type_id",
                        "right": 12
                    }]
                }
            ]
            globalQuery.groupBy = ['st.product_material_id']
            comboDataAPiCall.current.fillFiltersCombo(globalQuery)
                .then(getMaterials => {
                    console.log(getMaterials);
                    const materialOpts = [
                        { value: '', label: 'Select', },
                        ...getMaterials.map((material) => ({ ...material, value: material.product_material_id, label: material.product_material_name })),
                    ];
                    setMaterialOptions(materialOpts)
                });

        } catch (error) {
            console.log(error);
        }
    }

    const comboOnChange = async (key) => {
        switch (key) {
            case 'departmentId':
                var departmentIdVal = document.getElementById('cmb_department').value;
                setDepartmentId(departmentIdVal)
                if (departmentIdVal === "0") {
                    setHeaderName('Department')
                    setshowAddRecIndentIssueModal(true)
                    setTimeout(() => { $(".erp_top_Form").css({ "padding-top": "0px" }); }, 200)
                }
                if (departmentIdVal !== "" && departmentIdVal !== "0") {
                    resetGlobalQuery();
                    globalQuery.columns.push("field_id");
                    globalQuery.columns.push("field_name");
                    globalQuery.table = "cmv_department"
                    globalQuery.conditions.push({ field: "department_type", operator: "=", value: 'S' });
                    globalQuery.conditions.push({ field: "parent_department_id", operator: "=", value: departmentIdVal });
                    globalQuery.conditions.push({ field: "company_id", operator: "IN", values: [0, COMPANY_ID] });
                    globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                    const subDepartmentApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
                    setSubDepartmentOptions(subDepartmentApiCall)
                    setSubDepartmentId('')
                    if (departmentIdVal !== "") {
                        $('#error_cmb_department').hide();
                    }
                } else {
                    setSubDepartmentOptions([])
                    setSubDepartmentId('')
                }
                break;
            case 'subDepartment':
                var subdepartmentIdVal = document.getElementById('cmb_sub_department').value;
                setSubDepartmentId(subdepartmentIdVal)
                if (subdepartmentIdVal === "0") {
                    sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                    setHeaderName('Sub Department')
                    setshowAddRecIndentIssueModal(true)
                    setTimeout(() => { $(".erp_top_Form").css({ "padding-top": "0px" }); }, 200)
                }
                if (subdepartmentIdVal !== "" && subdepartmentIdVal !== "0") {
                    // load issue nos on sub-department  
                    await FnLoadMaterialList()
                    await FnLoadIssueNos(subdepartmentIdVal)
                    if (subdepartmentIdVal !== "") {
                        $('#error_cmb_sub_department').hide();
                    }
                } else {
                    setIssueNo('')
                    setIssueNoOptions([])
                }
                break;

            case 'product_material_id':
                let selected_material_id = materialsComboRef.current.value;
                if (selected_material_id !== "") {
                    // GET ISSUE NO's 
                    resetGlobalQuery();
                    globalQuery.columns = ["st.issue_no", "st.issue_date", "stm.set_no"];
                    globalQuery.table = "st_indent_material_issue_details st";
                    globalQuery.conditions.push({ field: "st.is_delete", operator: "=", value: 0 });
                    globalQuery.conditions.push({ field: "st.product_material_id", operator: "=", value: selected_material_id });
                    globalQuery.conditions.push({ field: "st.issue_item_status", operator: "IN", values: ['MI'] });
                    globalQuery.groupBy = ['st.product_material_id']

                    globalQuery.joins = [
                        {
                            "table": "st_indent_material_issue_master stm",
                            "type": "inner",
                            "on": [{
                                "left": "stm.issue_master_transaction_id",
                                "right": "st.issue_master_transaction_id"
                            },

                            {
                                "left": "stm.sub_department_id",
                                "right": cmb_sub_department
                            },
                            {
                                "left": "stm.indent_issue_type_id",
                                "right": 12
                            }
                            ]
                        }
                    ]

                    comboDataAPiCall.current.fillFiltersCombo(globalQuery)
                        .then(getIssueNos => {
                            const issueNoOpts = [
                                { value: '', label: 'Select', },
                                ...getIssueNos.map((issueNo) => ({
                                    ...issueNo,
                                    value: issueNo.issue_no,
                                    label: `${issueNo.issue_no}${issueNo.set_no ? ` [${issueNo.set_no}]` : ''}`,
                                    issue_date: issueNo.issue_date,
                                    set_no: issueNo.set_no,
                                })),];
                            setIssueNoOptions(issueNoOpts)
                        });


                    // GET LOT NO's 
                    resetGlobalQuery();
                    globalQuery.columns = ["st.issue_batch_no"];
                    globalQuery.table = "st_indent_material_issue_details st";
                    globalQuery.conditions.push({ field: "st.is_delete", operator: "=", value: 0 });
                    globalQuery.conditions.push({ field: "st.product_material_id", operator: "=", value: selected_material_id });
                    globalQuery.conditions.push({ field: "st.issue_item_status", operator: "IN", values: ['MI'] });
                    globalQuery.groupBy = ['st.issue_batch_no']

                    comboDataAPiCall.current.fillFiltersCombo(globalQuery)
                        .then(getIssueBatchNos => {
                            const issueBatchOpts = [
                                { value: '', label: 'Select', },
                                ...getIssueBatchNos.map((issueBatchNo) => ({ ...issueBatchNo, value: issueBatchNo.issue_batch_no, label: issueBatchNo.issue_batch_no })),
                            ];
                            setLotNoOptions(issueBatchOpts)
                        });
                }
                break;

            case 'lot_no':
                let selected_lot_no = lotNoComboRef.current.value;
                if (selected_lot_no !== "") {
                    // GET Lot NO's 
                    resetGlobalQuery();
                    globalQuery.columns = ["st.issue_no", "st.issue_date", "stm.set_no"];
                    globalQuery.table = "st_indent_material_issue_details st";
                    globalQuery.conditions.push({ field: "st.is_delete", operator: "=", value: 0 });
                    globalQuery.conditions.push({ field: "st.product_material_id", operator: "=", value: cmb_product_material_id });
                    globalQuery.conditions.push({ field: "st.issue_batch_no", operator: "=", value: selected_lot_no });
                    globalQuery.conditions.push({ field: "st.issue_item_status", operator: "IN", values: ['MI'] });
                    globalQuery.groupBy = ['st.product_material_id']

                    globalQuery.joins = [
                        {
                            "table": "st_indent_material_issue_master stm",
                            "type": "inner",
                            "on": [{
                                "left": "stm.issue_master_transaction_id",
                                "right": "st.issue_master_transaction_id"
                            },

                            {
                                "left": "stm.sub_department_id",
                                "right": cmb_sub_department
                            },
                            {
                                "left": "stm.indent_issue_type_id",
                                "right": 12
                            }
                            ]
                        }
                    ]

                    comboDataAPiCall.current.fillFiltersCombo(globalQuery)
                        .then(getIssueNos => {
                            const issueNoOpts = [
                                { value: '', label: 'Select', },
                                ...getIssueNos.map((issueNo) => ({ ...issueNo, value: issueNo.issue_no, label: issueNo.issue_no, issue_date: issueNo.issue_date, set_no: issueNo.set_no })),
                            ];
                            setIssueNoOptions(issueNoOpts)
                        });
                }
                break;

            case 'return_by':
                var return_by = returnByComboRef.current.value;
                setReturnBy(return_by)
                if (return_by === "0") {
                    sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                    setHeaderName('Requested By')
                    const newTab = window.open('/Masters/Employees', '_blank');
                    if (newTab) {
                        newTab.focus();
                    }
                }
                if (return_by !== "" && return_by !== "0") {
                    $('#error_cmb_return_by').hide();
                }
                break;

            case 'materialTypeId':
                var materialTypeId = document.getElementById('cmb_indent_issue_return_type_id').value;
                if (materialTypeId === "0") {
                    sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                    setHeaderName('Material Type')
                    setshowAddRecIndentIssueModal(true)
                    setTimeout(() => { $(".erp_top_Form").css({ "padding-top": "0px" }); }, 200)
                }
                setIssueReturnTypeId(materialTypeId)
                if (materialTypeId !== "0" && materialTypeId !== "") {
                    $('#error_cmb_indent_issue_return_type_id').hide();
                    await generateReturnNo();
                } else {
                    setIssueReturnNo("0")
                }
                break;

            case 'issue_no':
                let selected_issue_no = issueNoComboRef.current.value;
                if (selected_issue_no !== "") {
                    $('#error_cmb_issue_no').hide();
                    await FnShowIssueDetails();
                    const issueObj = issueNoOptions.find(item => item.value === selected_issue_no);
                    if (issueObj) {
                        setIssueDate(issueObj.issue_date);
                    }
                } else {
                    setMaterialIssueDetailsData([])
                }


            default:
                break;
        }
    }

    const displayRecordIndentIssueComponent = () => {
        switch (modalHeaderName) {
            case 'Requested By':
                return <FrmMEmployeesEntry />;

            case 'Department':
                return <FrmDepartmentEntry />;

            case 'Material Type':
                return <FrmMProductTypeEntry />;

            case 'Sub Department':
                const props = { btn_disabled: true, departmentType: "S", parent_department: cmb_department, parentDepIs_disable: true, deptTypeIs_disable: true };
                return <FrmDepartmentEntry {...props} />;
            default:
                return null;
        }
    }

    const generateReturnNo = async () => {
        const ptShortName = materialtypeOptions.find(item => item.field_id === parseInt($('#cmb_indent_issue_return_type_id').val()));
        const productTypeId = $('#cmb_indent_issue_return_type_id option:selected').val();
        const autoNoApiCall = await generateAutoNoAPiCall.current.generateTAutoNo("st_indent_material_issue_return_master", "issue_return_no", ptShortName.product_type_short_name, "MR", "5", "indent_issue_return_type_id", productTypeId);
        setIssueReturnNo(autoNoApiCall);
        return autoNoApiCall;
    };


    // product filter columns
    let requiredColumns = ['product_material_id', 'product_material_name', 'issue_batch_no',
        'product_material_unit_name', 'product_material_indent_quantity', 'product_material_indent_weight',
        'product_material_approved_quantity', 'product_material_approved_weight', 'godown_name', 'godown_section_name', 'godown_section_beans_name',
        'product_material_issue_quantity', 'product_material_issue_weight', 'product_material_issue_boxes', 'cone_per_wt', 'godown_id', 'godown_section_id', 'godown_section_beans_id'];

    // Fn for show indent summary modal details
    const FnShowIssueDetails = async () => {
        try {
            setIsLoading(true)
            let selected_issue_no = issueNoComboRef.current.value;

            if (selected_issue_no !== "") {
                resetGlobalQuery();
                globalQuery.columns = requiredColumns;
                globalQuery.table = "stv_indent_material_issue_details";
                globalQuery.conditions.push({ field: "issue_no", operator: "=", value: selected_issue_no, });
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0, });
                const issueDetailsList = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
                if (issueDetailsList?.length !== 0) {
                    setMaterialIssueDetailsData(issueDetailsList)
                }
            } else {
                setMaterialIssueDetailsData([])
            }
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false)
        }
    }

    // Function to handle input changes and update state variables
    const validateMaterialDetails = (event) => {
        const numCheck = /^\d{0,18}(?:\.\d{0,4})?$/;
        let indentItemsQtyinp = document.querySelector('#' + event.target.id);
        if (event.target.value === '' || event.target.value === null || !numCheck.test(event.target.value)) {
            indentItemsQtyinp.parentElement.dataset.tip = 'Please enter valid input...!';
            return false;
        } else {
            delete indentItemsQtyinp.parentElement.dataset.tip;
            return true;
        }
    }
    // Function to handle input changes and update state variables
    const FnOnChangeTblRow = async (currentRowData, event, arrayIndex, param) => {
        let clickedColName;
        let issueItemsQtyinp;

        clickedColName = event.target.getAttribute('Headers');               // Get the column name where event triggered.
        issueItemsQtyinp = document.querySelector('#' + event.target.id);

        switch (clickedColName) {
            case 'issue_return_item_status':
                if (event.target.value !== '') {
                    currentRowData[clickedColName] = event.target.value;
                    delete issueItemsQtyinp.parentElement.dataset.tip;
                }
                break;


            case 'issue_return_remark':
                currentRowData[clickedColName] = event.target.value;
                break;

            case 'product_material_issue_return_boxes':
                let no_of_boxes = validateNumberDateInput.current.decimalNumber(event.target.value, 4);
                if (currentRowData?.product_material_issue_boxes >= no_of_boxes) {
                    currentRowData[clickedColName] = no_of_boxes;
                    delete issueItemsQtyinp.parentElement.dataset.tip;
                } else {
                    issueItemsQtyinp.parentElement.dataset.tip = `Return boxes can't exceed issue boxes...!`;
                    currentRowData[clickedColName] = '';
                }
                break;

            case 'product_material_receipt_boxes':
                let receipt_no_of_boxes = validateNumberDateInput.current.decimalNumber(event.target.value, 4);
                if (currentRowData?.product_material_issue_return_boxes >= receipt_no_of_boxes) {
                    currentRowData[clickedColName] = receipt_no_of_boxes;
                    delete issueItemsQtyinp.parentElement.dataset.tip;
                } else {
                    issueItemsQtyinp.parentElement.dataset.tip = `Received boxes can't exceed return boxes...!`;
                    currentRowData[clickedColName] = '';
                }
                break;


            case 'product_material_issue_return_quantity':
                if (validateMaterialDetails(event)) {
                    let material_qty = event.target.value;
                    let product_material_issue_quantity = currentRowData.product_material_issue_quantity;

                    if (product_material_issue_quantity >= material_qty) {
                        currentRowData[clickedColName] = material_qty;
                        let product_std_wgt = currentRowData['cone_per_wt']
                        let product_material_wgt = material_qty * product_std_wgt;
                        currentRowData['product_material_issue_return_weight'] = validateNumberDateInput.current.decimalNumber(product_material_wgt, 4);
                        delete issueItemsQtyinp.parentElement.dataset.tip;
                    } else {
                        issueItemsQtyinp.parentElement.dataset.tip = `Return quantity can't exceed issue quantity...!`;
                        currentRowData[clickedColName] = '';
                    }
                } else {
                    currentRowData[clickedColName] = '';
                }
                break;

            case 'product_material_receipt_quantity':
                if (validateMaterialDetails(event)) {
                    let material_qty = event.target.value;
                    let product_material_issue_return_quantity = currentRowData.product_material_issue_return_quantity;

                    if (product_material_issue_return_quantity >= material_qty) {
                        currentRowData[clickedColName] = material_qty;
                        let product_std_wgt = currentRowData['cone_per_wt']
                        let product_material_wgt = material_qty * product_std_wgt;
                        currentRowData['product_material_receipt_weight'] = validateNumberDateInput.current.decimalNumber(product_material_wgt, 4);
                        delete issueItemsQtyinp.parentElement.dataset.tip;
                    } else {
                        issueItemsQtyinp.parentElement.dataset.tip = `Received quantity can't exceed return quantity...!`;
                        currentRowData[clickedColName] = '';
                    }
                } else {
                    currentRowData[clickedColName] = '';
                }
                break;

            default:
                break;
        }



        const detailData = [...materialIssueDetailsData]
        detailData[arrayIndex] = currentRowData
        setMaterialIssueDetailsData(detailData);

    };



    //-------------------------------------------End Details section --------------------------------------------------------------------------
    const validateFields = () => {
        validate.current.validateFieldsOnChange('material_issue_form_id')
    }
    // ---------------------fn for save indent ----------------------------------------------------------------
    const FnAddReturnEntry = async () => {
        try {
            let checkIsValidate = true;
            if (keyForViewUpdate !== "view" && keyForViewUpdate !== "delete") {
                checkIsValidate = await FnValidateIssueOrder();
            }
            if (checkIsValidate === true) {
                setIsLoading(true)
                let lastIndentissueNo = txt_issue_return_no;
                if (keyForViewUpdate === "") {
                    lastIndentissueNo = await generateReturnNo();
                }

                let json = { 'TransHeaderData': {}, 'TransDetailData': [], 'commonIds': {} }

                // TransHeaderData
                const indentMasterFormData = {
                    issue_return_master_transaction_id: keyForViewUpdate !== "" ? issue_return_master_transaction_id : 0,
                    company_id: COMPANY_ID,
                    company_branch_id: COMPANY_BRANCH_ID,
                    financial_year: FINANCIAL_SHORT_NAME,
                    issue_return_no: lastIndentissueNo,
                    issue_return_date: dt_material_issue_date,
                    issue_no: cmb_issue_no,
                    indent_issue_return_type_id: cmb_indent_issue_return_type_id,
                    indent_issue_return_type: $('#cmb_indent_issue_return_type_id').find(":selected").text(),
                    department_id: cmb_department,
                    sub_department_id: cmb_sub_department,
                    return_by_id: cmb_return_by,
                    issue_return_status: cmb_return_status,
                    received_by_id: cmb_received_by_id,
                    received_date: dt_received_date,
                    remark: txt_remark,
                    is_active: chk_isactive,
                    created_by: UserName,
                    modified_by: idList !== "" ? UserName : null,
                }
                json.TransHeaderData = indentMasterFormData;

                for (let issueItem = 0; issueItem < materialIssueDetailsData.length; issueItem++) {
                    const indentIssueDetailsRow = materialIssueDetailsData[issueItem];

                    const indentReturnRow = {};
                    indentReturnRow.company_id = COMPANY_ID
                    indentReturnRow.company_branch_id = COMPANY_BRANCH_ID
                    indentReturnRow.financial_year = FINANCIAL_SHORT_NAME
                    indentReturnRow.issue_return_details_transaction_id = keyForViewUpdate !== "" ? indentIssueDetailsRow.issue_return_details_transaction_id : 0
                    indentReturnRow.product_material_id = indentIssueDetailsRow.product_material_id
                    indentReturnRow.product_material_issue_return_quantity = indentIssueDetailsRow.product_material_issue_return_quantity
                    indentReturnRow.product_material_issue_return_weight = indentIssueDetailsRow.product_material_issue_return_weight
                    indentReturnRow.product_material_issue_return_boxes = indentIssueDetailsRow.product_material_issue_return_boxes || 0
                    indentReturnRow.product_material_receipt_quantity = indentIssueDetailsRow?.product_material_receipt_quantity
                    indentReturnRow.product_material_receipt_weight = indentIssueDetailsRow?.product_material_receipt_weight
                    indentReturnRow.product_material_receipt_boxes = indentIssueDetailsRow?.product_material_receipt_boxes || 0
                    indentReturnRow.issue_return_item_status = cmb_return_status === 'R' ? 'R' : 'P'
                    indentReturnRow.godown_id = indentIssueDetailsRow?.godown_id
                    indentReturnRow.godown_section_id = indentIssueDetailsRow?.godown_section_id
                    indentReturnRow.godown_section_beans_id = indentIssueDetailsRow?.godown_section_beans_id
                    indentReturnRow.issue_return_remark = indentIssueDetailsRow?.issue_return_remark
                    indentReturnRow.issue_batch_no = indentIssueDetailsRow?.issue_batch_no
                    indentReturnRow.cone_per_wt = indentIssueDetailsRow?.cone_per_wt
                    json.TransDetailData.push(indentReturnRow);

                }
                console.log(json.TransDetailData);

                // Common Ids
                json.commonIds.company_id = COMPANY_ID
                json.commonIds.issue_no = keyForViewUpdate === '' ? lastIndentissueNo : txt_issue_return_no
                json.commonIds.financial_year = FINANCIAL_SHORT_NAME
                const formData = new FormData();
                formData.append(`StRawMaterialReturnMasterData`, JSON.stringify(json))
                const requestOptions = {
                    method: 'POST',
                    body: formData
                };
                const acceptFlag = keyForViewUpdate === "Receive" ? "Receive" : "AddOrUpdate";

                const apicall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/StRawMaterialReturnMaster/FnAddUpdateRecord/${acceptFlag}`, requestOptions)
                const responce = await apicall.json();
                if (responce.success === 0) {
                    setErrMsg(responce.error)
                    setShowErrorMsgModal(true)
                } else {
                    if (responce.responseIndentIssueMaster.hasOwnProperty('issue_return_no')) {
                        setModalOrderDetails(`Issue Return No: ${responce.responseIndentIssueMaster.issue_return_no}`)
                    }
                    setSuccMsg(responce.message)
                    setShowSuccessMsgModal(true);
                }
                setIsLoading(false)
            }
        } catch (error) {
            console.log("error: ", error)
        } finally {
            setIsLoading(false)
        }
    }



    const FnValidateIssueOrder = async () => {

        let headerDataIsValid = await validate.current.validateForm("material_issue_form_id");
        if (!headerDataIsValid) { return false; }

        if (cmb_issue_no === "" || cmb_issue_no === '0') {
            $("#error_cmb_issue_no").text("Please fill this field...!");
            $("#error_cmb_issue_no").show();
            $("#cmb_issue_no").focus();
            return false;
        }
        let return_by = cmb_return_by || '';
        if (return_by === "" || return_by === '0') {
            $("#error_cmb_return_by").text("Please fill this field...!");
            $("#error_cmb_return_by").show();
            $("#cmb_return_by").focus();
            return false;
        }
        let issueDetailsIsValid = false;
        let addedIssuematerial = $('#issue-details-tbl tbody tr').length;
        if (addedIssuematerial <= 0 && keyForViewUpdate !== 'view') {
            setErrMsg('Please Add atleast one Issued Detail.');
            setShowErrorMsgModal(true);
            return issueDetailsIsValid;
        } else {
            const tableRows = $('#issue-details-tbl tbody tr');
            issueDetailsIsValid = true;
            tableRows.each(function () {

                if (keyForViewUpdate === "") {
                    const requisition_quantity = parseFloat($(this).find('input[id^="product_material_issue_return_quantity_"]').val());
                    if (isNaN(requisition_quantity) || requisition_quantity === "" || requisition_quantity === 0) {
                        $(this).find(`input[id^='product_material_issue_return_quantity_']`).parent().attr('data-tip', 'Please enter a valid Quantity...!');
                        $(this).find(`input[id^='product_material_issue_return_quantity_']`).focus();
                        setOpenIssueDetailsAccord(true)
                        issueDetailsIsValid = false;
                        return false;
                    }
                }
                else if (keyForViewUpdate === "Receive") {
                    const issueQty = parseFloat($(this).find('input[id^="product_material_receipt_quantity_"]').val());
                    if (isNaN(issueQty) || issueQty === "") {
                        $(this).find(`input[id^='product_material_receipt_quantity_']`).parent().attr('data-tip', 'Please enter a valid Quantity...!');
                        $(this).find(`input[id^='product_material_receipt_quantity_']`).focus();
                        setOpenIssueDetailsAccord(true)
                        issueDetailsIsValid = false;
                        return false;
                    }
                }
            });
            return issueDetailsIsValid;
        }
    }



    const FnClearFormFields = async () => {
        setIssueReturnTypeId('')
        setIssueReturnNo('0')
        setIssueDate(todayDate)
        setDepartmentId(DEPARTMENT_ID);
        setSubDepartmentId('')
        setReceivedById('');
        setReturnStatus("P");
        setRemark('');
        setIsActive(true);
        setmaterialissueDate(todayDate);
        setReturnBy('')
        setMaterialIssueDetailsData([])
    }

    const moveToListing = () => {
        let Path = compType === 'Register' ? '/Transactions/RawMaterialReturnListing/reg' : `/Transactions/RawMaterialReturnListing`;
        Path = `${requestfor ? `${Path}/${requestfor}` : `${Path}`}`;
        navigate(Path);
    }

    $('body').on('click', '.RemoveTrBtn', function () {
        var $tr = $(this).closest('tr');
        $tr.remove();
    });

    $(document).on('click', '.erp_input_field.text-end.remove0', function () {
        if ($(this).val() === "0") {
            $(this).val("");
        }
    });

    $(document).on('mouseup mousedown', function (e) {
        let inputBoxes = $(".erp_input_field.text-end.remove0");
        if (e.target.className !== "erp_input_field text-end remove0") {
            inputBoxes.each(function () {
                if ($(this).val() === "") {
                    $(this).val("0");
                }
            });
        }
    });

    $(document).on('click', '.remove_input_zero', function () {
        if ($(this).val() === "0") {
            $(this).val("")
        }
    });
    $(document).on('mouseup mousedown', function (e) {
        let inputBoxes = $(".remove_input_zero");
        inputBoxes.each(function () {
            if ($(this).val() === "") {
                $(this).val("0");
            }
        });
    });

    // fn to remove material
    const removeMaterial = (indexToRemove, setStateCallback) => {
        if (indexToRemove === undefined || indexToRemove < 0 || indexToRemove >= materialIssueDetailsData.length) {
            console.error("Invalid index provided.");
            return;
        }
        if (keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'view') {
            // Create a new array excluding the element at indexToRemove
            const updatedDetailsData = materialIssueDetailsData.filter((_, index) => index !== indexToRemove);

            // Update state using the provided callback
            setStateCallback(updatedDetailsData);
        } else {
            console.error("Cannot remove material in 'approve' or 'view' mode.");
        }
    }

    const renderDetailsTable = useMemo(() => {
        return <>
            <Table className="erp_table erp_table_scroll" id='issue-details-tbl' responsive bordered striped>
                <thead className="erp_table_head">
                    <tr>
                        <th className={`erp_table_th  ${keyForViewUpdate === "" || keyForViewUpdate === "update" ? 'display' : 'd-none'}`}>Action</th>
                        <th className="erp_table_th">Sr.no</th>
                        <th className="erp_table_th">Material Name</th>
                        <th className="erp_table_th">Lot No</th>
                        <th className="erp_table_th">Issue Quantity</th>
                        <th className="erp_table_th">Issue Weight</th>
                        <th className={`erp_table_th`}>Issue Boxes</th>
                        <th className="erp_table_th">Return Quantity</th>
                        <th className="erp_table_th">Return Weight</th>
                        <th className="erp_table_th">Return Boxes</th>
                        <th className="erp_table_th">Issue Return Remark</th>
                        {
                            keyForViewUpdate === 'Receive' || cmb_return_status === 'R' ?
                                <>
                                    <th className={`erp_table_th`}>Received Quantity</th>
                                    <th className={`erp_table_th`}>Received Weight</th>
                                    <th className={`erp_table_th`}>Received Boxes</th>
                                </> : null
                        }

                        <th className="erp_table_th">Requisition Quantity</th>
                        <th className="erp_table_th">Requisition Weight</th>
                        <th className="erp_table_th">Approved Quantity</th>
                        <th className="erp_table_th">Approved Weight</th>
                        <th className={`erp_table_th`} style={{ textAlign: 'center' }}>Material Godown</th>
                        <th className={`erp_table_th `} style={{ textAlign: 'center' }}>Godown Section</th>
                        <th className={`erp_table_th `} style={{ textAlign: 'center' }}>Godown Section Beans</th>
                        <th className="erp_table_th">Routing Code</th>
                        <th className={`erp_table_th`} style={{ paddingRight: '40px' }} >Issue Item Status</th>
                        <th className="erp_table_th">Cone per Weight</th>
                    </tr>
                </thead>
                <tbody>
                    {materialIssueDetailsData.map((issueItem, Index) =>
                        <tr rowindex={Index} className="sticky-column">
                            <td className={`erp_table_td  ${keyForViewUpdate === '' || keyForViewUpdate === 'update' ? 'display' : 'd-none'}`}><div style={{ display: "flex" }}>
                                <Tooltip title="Delete" placement="top">
                                    <MDTypography className="erp-view-btn">
                                        <MdDelete className="erp-delete-btn" id={`delete_btn_${Index}`} onClick={() => removeMaterial(Index, setMaterialIssueDetailsData)} />
                                    </MDTypography>
                                </Tooltip>
                            </div>
                            </td>
                            <td className="erp_table_td text-end sticky-column">{Index + 1}</td>
                            <td className={`erp_table_td sticky-column`}>{issueItem.product_material_name}</td>
                            <td className={`erp_table_td sticky-column`}>{issueItem.issue_batch_no}</td>
                            <td className="erp_table_td text-end">{issueItem.product_material_issue_quantity}   </td>
                            <td className={`erp_table_td text-end `}>{issueItem.product_material_issue_weight}</td>
                            <td className={`erp_table_td text-end `} > {issueItem.product_material_issue_boxes} </td>
                            <td className="erp_table_td text-end">
                                {keyForViewUpdate === '' ?
                                    <input type="text" id={`product_material_issue_return_quantity_${Index}`} className="remove_input_zero erp_input_field text-end remove0 mb-0 issueField"
                                        value={issueItem.product_material_issue_return_quantity} onChange={(e) => { FnOnChangeTblRow(issueItem, e, Index); }} Headers='product_material_issue_return_quantity' />
                                    :
                                    issueItem.product_material_issue_return_quantity
                                }
                            </td>
                            <td className={`erp_table_td text-end `}>{issueItem.product_material_issue_return_weight}</td>

                            <td className={`erp_table_td text-end `} >
                                {keyForViewUpdate === '' ?
                                    <input
                                        type="text"
                                        id={`product_material_issue_return_boxes_${Index}`}
                                        className="remove_input_zero erp_input_field text-end remove0 mb-0 issueField"
                                        value={issueItem.product_material_issue_return_boxes}
                                        onChange={(e) => FnOnChangeTblRow(issueItem, e, Index)}
                                        headers="product_material_issue_return_boxes"
                                    />
                                    :
                                    issueItem.product_material_issue_return_boxes
                                }

                            </td>

                            <td className="erp_table_td">
                                {keyForViewUpdate === '' ?
                                    <input type="text" id={`issue_return_remark_${Index}`} className="erp_input_field mb-0 issueField"
                                        value={issueItem.issue_return_remark} onChange={(e) => { FnOnChangeTblRow(issueItem, e, Index); }} Headers='issue_return_remark' />
                                    :
                                    issueItem.issue_return_remark
                                }
                            </td>

                            {/* Receives quantity */}
                            {
                                keyForViewUpdate === 'Receive' || cmb_return_status === 'R' ? <>
                                    <td className="erp_table_td text-end">
                                        {keyForViewUpdate === 'Receive' ?
                                            <input type="text" id={`product_material_receipt_quantity_${Index}`} className="remove_input_zero erp_input_field text-end remove0 mb-0 issueField"
                                                value={issueItem.product_material_receipt_quantity} onChange={(e) => { FnOnChangeTblRow(issueItem, e, Index); }} Headers='product_material_receipt_quantity' />
                                            :
                                            issueItem.product_material_receipt_quantity
                                        }
                                    </td>
                                    <td className={`erp_table_td text-end `}>{issueItem.product_material_receipt_weight}</td>

                                    <td className={`erp_table_td text-end `} >
                                        {keyForViewUpdate === 'Receive' ?
                                            <input
                                                type="text"
                                                id={`product_material_receipt_boxes_${Index}`}
                                                className="remove_input_zero erp_input_field text-end remove0 mb-0 issueField"
                                                value={issueItem.product_material_receipt_boxes}
                                                onChange={(e) => FnOnChangeTblRow(issueItem, e, Index)}
                                                headers="product_material_receipt_boxes"
                                            />
                                            :
                                            issueItem.product_material_receipt_boxes
                                        }

                                    </td>
                                </> : null
                            }


                            <td className="erp_table_td text-end">{issueItem.product_material_indent_quantity}  </td>
                            <td className={`erp_table_td text-end sticky-column`}>{issueItem.product_material_indent_weight}</td>
                            <td className="erp_table_td text-end">  {issueItem.product_material_approved_quantity}</td>
                            <td className="erp_table_td text-end">{issueItem.product_material_approved_weight}</td>

                            <td className={`erp_table_td `}>
                                {issueItem.godown_name}
                            </td>
                            <td className={`erp_table_td `}>
                                {issueItem.godown_section_name}
                            </td>
                            <td className={`erp_table_td`}>
                                {issueItem.godown_section_beans_name}
                            </td>
                            <td className="erp_table_td">{issueItem.routing_code}</td>
                            <td className="erp_table_td">
                                <select id={`issue_return_item_status_${Index}`} disabled value={issueItem.issue_return_item_status}
                                    className="form-select form-select-sm mb-1" Headers='issue_return_item_status' onChange={(e) => { FnOnChangeTblRow(issueItem, e, Index); }}>
                                    <option value="P">Pending</option>
                                    <option value="R">Received</option>
                                </select>
                            </td>
                            <td className="erp_table_td text-end">{issueItem.cone_per_wt}</td>
                        </tr>
                    )}
                </tbody>
            </Table>
        </>
    }, [materialIssueDetailsData, keyForViewUpdate])


    // ---------------------------------------------------------------------------------------------------------------------------
    //Purchase Requisition Print Start
    const requisitionIssueContent = {
        company: {
            company: '',
            company_branch: '',
        },

        issuedetails: {
            issue_no: '',
            issue_date: '',
            issue_type: '',
        },
        header: {
            issue_group_type: '',
            department_name: '',
            sub_department_name: '',
            requisition_by_name: '',
            issued_by_name: '',
            approved_by_name: '',
            approved_date: '',
            requisition_date: '',
            remark: '',
        },
        footer: {
            requisition_by_name: '',
            issued_by_name: '',
            approved_by_name: '',



            sumofStockQuantity: '',
            sumIndentQuantity: '',
            sumofApprovedQuantity: '',
            sumofRejectedQuantity: '',
            sumofIssuedQuantity: '',

        },
        items: [],
    };
    const printIssueSlip = async (openPrintModal) => {

        // //Issue details
        // requisitionIssueContent.issuedetails.issue_no = IndentIssueMasterRecord.issue_no
        // requisitionIssueContent.issuedetails.issue_date = validateNumberDateInput.current.formatDateToDDMMYYYY(IndentIssueMasterRecord.issue_date)
        // requisitionIssueContent.issuedetails.issue_version = IndentIssueMasterRecord.issue_version
        // requisitionIssueContent.issuedetails.issue_type = $('#cmb_indent_issue_return_type_id').find(":selected").text()

        // //Company details
        // requisitionIssueContent.company.company = IndentIssueDetailsRecords[0].company_name
        // requisitionIssueContent.company.company_branch = IndentIssueDetailsRecords[0].company_branch_name

        // // //Customer details
        // // requisitionIssueContent.customerDetails.customer = IndentIssueMasterRecord.customer_name
        // // requisitionIssueContent.customerDetails.address = IndentIssueMasterRecord.cust_branch_address1
        // // requisitionIssueContent.customerDetails.state = IndentIssueMasterRecord.customer_state_name
        // // requisitionIssueContent.customerDetails.contact = IndentIssueMasterRecord.cust_branch_phone_no
        // // requisitionIssueContent.customerDetails.email = IndentIssueMasterRecord.cust_branch_EmailId

        // // header Details
        // requisitionIssueContent.header.issue_group_type = IndentIssueMasterRecord.issue_group_type
        // requisitionIssueContent.header.department_name = IndentIssueMasterRecord.department_name
        // requisitionIssueContent.header.sub_department_name = IndentIssueMasterRecord.sub_department_name
        // // requisitionIssueContent.header.cost_center_name = IndentIssueMasterRecord.cost_center_name
        // requisitionIssueContent.header.approved_by_name = IndentIssueMasterRecord.approved_by_name
        // requisitionIssueContent.header.approved_date = validateNumberDateInput.current.formatDateToDDMMYYYY(IndentIssueMasterRecord.approved_date)
        // requisitionIssueContent.header.issued_by_name = IndentIssueMasterRecord.issued_by_name
        // requisitionIssueContent.header.remark = IndentIssueMasterRecord.remark
        // requisitionIssueContent.header.requisition_by_name = IndentIssueMasterRecord.requisition_by_name


        // //footer details
        // requisitionIssueContent.footer.requisition_by_name = IndentIssueMasterRecord.requisition_by_name
        // requisitionIssueContent.header.requisition_date = validateNumberDateInput.current.formatDateToDDMMYYYY(IndentIssueMasterRecord.requisition_date)

        // requisitionIssueContent.footer.approved_by_name = IndentIssueMasterRecord.approved_by_name
        // requisitionIssueContent.footer.issued_by_name = IndentIssueMasterRecord.issued_by_name

        // // let sumStockQuantity = 0;
        // let sumIndentQuantity = 0;
        // let sumofApprovedQuantity = 0;
        // // let sumofRejectedQuantity = 0;
        // let sumofIssuedQuantity = 0;

        // for (let index = 0; index < IndentIssueDetailsRecords.length; index++) {
        //     const element = IndentIssueDetailsRecords[index];

        //     // Add product_material_stock_quantity to the sum
        //     sumIndentQuantity += element.product_material_indent_quantity;
        //     sumofApprovedQuantity += element.product_material_approved_quantity;
        //     sumofIssuedQuantity += element.product_material_issue_quantity;

        //     const detailsData = {
        //         sr_no: index + 1,
        //         product_material_name: element.product_material_name === undefined || element.product_material_name === null ? "" : element.product_material_name,
        //         material_tech_spect: element.product_rm_tech_spect === undefined || element.product_rm_tech_spect === null ? "" : element.product_rm_tech_spect,
        //         product_make_name: element.product_make_name === 'NA' || element.product_make_name === undefined || element.product_make_name === null ? "" : element.product_make_name,
        //         product_material_stock_quantity: element.product_material_stock_quantity,
        //         unit: element.product_material_unit_name,
        //         product_lead_time: element.product_lead_time,
        //         product_material_indent_quantity: element.product_material_indent_quantity,
        //         product_material_approved_quantity: element.product_material_approved_quantity,
        //         product_material_issue_quantity: element.product_material_issue_quantity,
        //         cost_center_name: element.cost_center_name
        //     }
        //     requisitionIssueContent.items.push(detailsData)
        //     console.log(detailsData);
        // }

        // // Assign calculated values to footer
        // requisitionIssueContent.footer.sumIndentQuantity = sumIndentQuantity;
        // requisitionIssueContent.footer.sumofApprovedQuantity = sumofApprovedQuantity;
        // requisitionIssueContent.footer.sumofIssuedQuantity = sumofIssuedQuantity;

        // if (openPrintModal) {
        //     if (parseFloat(IndentIssueDetailsRecords.length) <= 10) {
        //         navigator('/Invoice', { state: { invoiceData: requisitionIssueContent, navigationLink: '/Transactions/TRawMaterialRequisition/FrmRawMaterialRequisitionEntry', invoiceType: 'IR', title: 'Requisition/Issue', idList: idList, keyForViewUpdate: keyForViewUpdate, requestfor: requestfor } });
        //     } else {
        //         navigator('/Invoice', { state: { invoiceData: requisitionIssueContent, navigationLink: '/Transactions/TRawMaterialRequisition/FrmRawMaterialRequisitionEntry', invoiceType: 'MI', title: 'Requisition/Issue', idList: idList, keyForViewUpdate: keyForViewUpdate, requestfor: requestfor } });
        //     }
        // } else {
        //     // return the html code.
        //     return renderToString(<MaterialIssueSlip invoiceContent={requisitionIssueContent} />);
        // }
    };

    //---------------------------------------------------------Print Functionality End----------------------------------------------

    return (
        <>
            <ComboBox ref={comboDataAPiCall} />
            <ExcelExport ref={exlsExp} />
            <GenerateTAutoNo ref={generateAutoNoAPiCall} />
            <FrmValidations ref={validate} />
            <ValidateNumberDateInput ref={validateNumberDateInput} />
            <DashboardLayout>
                <>
                    {isLoading ?
                        <div className="spinner-overlay"  >
                            <div className="spinner-container">
                                <CircularProgress color="primary" />
                                <span>Loading...</span>
                            </div>
                        </div> :
                        ''}

                    <div className='card p-1'>
                        <div className='card-header text-center py-0'>
                            <label className='erp-form-label-lg main_heding'> Yarn Return From Dept. To Godown<span id="submain_heading">(Creation)</span></label>
                        </div>
                        <form id="material_issue_form_id">
                            <div className="row p-1">
                                {/* //first column */}
                                <div className="col-sm-6 erp_form_col_div">
                                    <div className='row'>
                                        <div className='col-sm-3 col-12'>
                                            <Form.Label className="erp-form-label">Issue Return Type<span className="required">*</span> </Form.Label>
                                        </div>
                                        <div className='col-sm-9 col-12'>
                                            <select id="cmb_indent_issue_return_type_id" className="form-select form-select-sm" value={cmb_indent_issue_return_type_id} onChange={() => { comboOnChange('materialTypeId'); validateFields(); }}  >
                                                <option value="">Select</option>
                                                <option value="0">Add New Record+</option>
                                                {materialtypeOptions.length !== 0 ? (
                                                    <>
                                                        {materialtypeOptions.map(materialTypeId => (
                                                            <option key={materialTypeId.field_id} value={materialTypeId.field_id}>
                                                                {materialTypeId.field_name}
                                                            </option>
                                                        ))}
                                                    </>
                                                ) : null}
                                            </select>
                                            <MDTypography variant="button" id="error_cmb_indent_issue_return_type_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-sm-3 col-12">
                                            <Form.Label className="erp-form-label">Return Issue No, Date <span className="required">*</span> </Form.Label>
                                        </div>
                                        <div className="col-sm-9 col-12">
                                            <div className="row">
                                                <div className="col-12 col-md-8 pe-md-0">
                                                    <Form.Control type="text" id="h" className="erp_input_field" value={txt_issue_return_no} disabled />
                                                    <MDTypography variant="button" id="txt_issue_return_no" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                                </div>

                                                <div className="col-12 col-md-4 pt-md-0 pt-3">
                                                    <Form.Control type="date" id='dt_return_date' className="erp_input_field" value={dt_return_date} onChange={e => { setReturnDate(e.target.value); validateFields(); }} />
                                                    <MDTypography variant="button" id="error_dt_return_date" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                    </MDTypography>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className='col-sm-3'>
                                            <Form.Label className="erp-form-label">Department <span className="required">*</span></Form.Label>
                                        </div>
                                        <div className='col'>
                                            <select id="cmb_department" className="form-select form-select-sm" value={cmb_department} onChange={() => { comboOnChange('departmentId'); validateFields(); }} >
                                                <option value="">Select</option>
                                                <option value="0">Add New Record+</option>
                                                {
                                                    departmentIdOPtions.length !== 0 ? (
                                                        <>
                                                            {departmentIdOPtions?.map(departmentId => (
                                                                <option value={departmentId.field_id}>{departmentId.field_name}</option>
                                                            ))}
                                                        </>
                                                    ) : null
                                                }
                                            </select>
                                            <MDTypography variant="button" id="error_cmb_department" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-sm-3'>
                                            <Form.Label className="erp-form-label">Sub Department <span className="required">*</span></Form.Label>
                                        </div>
                                        <div className='col'>
                                            <select id="cmb_sub_department" className="form-select form-select-sm" value={cmb_sub_department} onChange={() => { comboOnChange('subDepartment'); validateFields(); }}>
                                                <option value="">Select</option>
                                                <option value="0">Add New Record+</option>
                                                {subDepartmentOPtions?.map(departmentId => (
                                                    <option value={departmentId.field_id}>{departmentId.field_name}</option>
                                                ))}
                                            </select>
                                            <MDTypography variant="button" id="error_cmb_sub_department" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>

                                    {keyForViewUpdate !== '' ? <>
                                        <div className='row'>
                                            <div className='col-sm-3 col-12'>
                                                <Form.Label className="erp-form-label">Yarn Count</Form.Label>
                                            </div>
                                            <div className={`col-sm-9 col-12`}>
                                                <Select ref={materialsComboRef}
                                                    options={materialOptions}
                                                    isDisabled={['view', 'update', 'Receive'].includes(keyForViewUpdate)}
                                                    inputId="cmb_issue_no" // Provide the ID for the input box
                                                    value={materialOptions.find(option => option.value === cmb_product_material_id) || null}
                                                    onChange={(selectedOpt) => {
                                                        setProductMaterialId(selectedOpt.value);
                                                        materialsComboRef.current = selectedOpt;
                                                        comboOnChange('product_material_id');
                                                        validateFields();
                                                    }}
                                                    placeholder="Search for Issue No..."
                                                    className="form-search-custom"
                                                    classNamePrefix="custom-select" // Add custom prefix for class names
                                                    styles={{
                                                        option: (provided, state) => ({
                                                            ...provided, fontSize: '12px' // Adjust the font size as per your requirement
                                                        }),
                                                        singleValue: (provided, state) => ({
                                                            ...provided, fontSize: '12px' // Adjust the font size as per your requirement
                                                        }),
                                                        input: (provided, state) => ({
                                                            ...provided, fontSize: '12px' // Adjust the font size as per your requirement
                                                        })
                                                    }}
                                                />
                                                <MDTypography variant="button" id="error_cmb_issue_no" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                </MDTypography>
                                            </div>
                                        </div>

                                        <div className='row'>
                                            <div className='col-sm-3 col-12'>
                                                <Form.Label className="erp-form-label">Lot No</Form.Label>
                                            </div>
                                            <div className={`col-sm-9 col-12`}>
                                                <Select ref={lotNoComboRef}
                                                    options={lotNoOptions}
                                                    isDisabled={['view', 'update', 'Receive'].includes(keyForViewUpdate)}
                                                    inputId="cmb_lot_no" // Provide the ID for the input box
                                                    value={lotNoOptions.find(option => option.value === cmb_lot_no) || null}
                                                    onChange={(selectedOpt) => {
                                                        setLotNo(selectedOpt.value);
                                                        lotNoComboRef.current = selectedOpt;
                                                        comboOnChange('lot_no');
                                                        validateFields();
                                                    }}
                                                    placeholder="Search for Lot No..."
                                                    className="form-search-custom"
                                                    classNamePrefix="custom-select" // Add custom prefix for class names
                                                    styles={{
                                                        option: (provided, state) => ({
                                                            ...provided, fontSize: '12px' // Adjust the font size as per your requirement
                                                        }),
                                                        singleValue: (provided, state) => ({
                                                            ...provided, fontSize: '12px' // Adjust the font size as per your requirement
                                                        }),
                                                        input: (provided, state) => ({
                                                            ...provided, fontSize: '12px' // Adjust the font size as per your requirement
                                                        })
                                                    }}
                                                />
                                                <MDTypography variant="button" id="error_cmb_lot_no" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                </MDTypography>
                                            </div>
                                        </div>
                                    </> : null}
                                    <div className='row'>
                                        <div className='col-sm-3 col-12'>
                                            <Form.Label className="erp-form-label">Issue No.<span className="required">*</span></Form.Label>
                                        </div>
                                        <div className={`col-sm-5 col-10`}>
                                            <Select ref={issueNoComboRef}
                                                options={issueNoOptions}
                                                isDisabled={['view', 'update', 'Receive'].includes(keyForViewUpdate)}
                                                inputId="cmb_issue_no" // Provide the ID for the input box
                                                value={issueNoOptions.find(option => option.value === cmb_issue_no) || null}
                                                onChange={(selectedOpt) => {
                                                    setIssueNo(selectedOpt.value);
                                                    issueNoComboRef.current = selectedOpt;
                                                    comboOnChange('issue_no');
                                                    validateFields();
                                                }}
                                                placeholder="Search for Issue No..."
                                                className="form-search-custom"
                                                classNamePrefix="custom-select" // Add custom prefix for class names
                                                styles={{
                                                    option: (provided, state) => ({
                                                        ...provided, fontSize: '12px' // Adjust the font size as per your requirement
                                                    }),
                                                    singleValue: (provided, state) => ({
                                                        ...provided, fontSize: '12px' // Adjust the font size as per your requirement
                                                    }),
                                                    input: (provided, state) => ({
                                                        ...provided, fontSize: '12px' // Adjust the font size as per your requirement
                                                    })
                                                }}
                                            />
                                            <MDTypography variant="button" id="error_cmb_issue_no" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                        <div className={`col-sm-4 col-10`}>
                                            <Form.Control type="date" id='dt_issue_date' className="erp_input_field" value={dt_issue_date} disabled />
                                            <MDTypography variant="button" id="error_dt_issue_date" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>
                                </div>
                                {/* //second column */}
                                <div className="col-sm-6 erp_form_col_div">
                                    <div className='row'>
                                        <div className='col-sm-3 col-12'>
                                            <Form.Label className="erp-form-label">Return By<span className="required">*</span></Form.Label>
                                        </div>
                                        <div className={`col-sm-5 col-10`}>
                                            <Select ref={returnByComboRef}
                                                options={returnOptions}
                                                isDisabled={['view', 'approve'].includes(keyForViewUpdate)}
                                                inputId="cmb_return_by" // Provide the ID for the input box
                                                value={returnOptions.find(option => option.value === cmb_return_by) || null}
                                                onChange={(selectedOpt) => {
                                                    setReturnBy(selectedOpt.value);
                                                    returnByComboRef.current = selectedOpt;
                                                    // comboOnChange('return_by');
                                                    validateFields();
                                                }}
                                                placeholder="Search for Reuesition by..."
                                                className="form-search-custom"
                                                classNamePrefix="custom-select" // Add custom prefix for class names
                                                styles={{
                                                    option: (provided, state) => ({
                                                        ...provided, fontSize: '12px' // Adjust the font size as per your requirement
                                                    }),
                                                    singleValue: (provided, state) => ({
                                                        ...provided, fontSize: '12px' // Adjust the font size as per your requirement
                                                    }),
                                                    input: (provided, state) => ({
                                                        ...provided, fontSize: '12px' // Adjust the font size as per your requirement
                                                    })
                                                }}
                                            />
                                            <MDTypography variant="button" id="error_cmb_return_by" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>

                                    </div>

                                    {cmb_return_status === "R" ?
                                        <>
                                            <div className='row'>
                                                <div className='col-sm-3 col-12'>
                                                    <Form.Label className="erp-form-label">Received By  </Form.Label>
                                                </div>
                                                <div className={`col-sm-${keyForViewUpdate === 'view' ? '9' : '8'} col-10`}>
                                                    <select id="cmb_received_by_id" className="form-select form-select-sm " value={cmb_received_by_id} optional="optional"
                                                        onChange={() => {
                                                            validateFields();
                                                        }} disabled>
                                                        <option value="">Select</option>
                                                        {employeeOptions.length !== 0 ? (
                                                            <>
                                                                {employeeOptions?.map(acceptedById => (
                                                                    <option value={acceptedById.field_id}>{acceptedById.field_name}</option>
                                                                ))}
                                                            </>
                                                        ) : null}
                                                    </select>
                                                    <MDTypography variant="button" id="error_cmb_received_by_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                    </MDTypography>
                                                </div>
                                            </div>
                                            <div className={`row`}>
                                                <div className='col-sm-3 col-12'>
                                                    <Form.Label className="erp-form-label">Received Date</Form.Label>
                                                </div>
                                                <div className={`col-sm-${keyForViewUpdate === 'view' ? '9' : '8'} col-10`}>
                                                    <Form.Control type="date" id='dt_received_date' className="erp_input_field" value={dt_received_date} onChange={e => { setReceivedDate(e.target.value); validateFields(); }} min={currentDate} optional='optional' />
                                                    <MDTypography variant="button" id="error_dt_received_date" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                    </MDTypography>
                                                </div>
                                            </div>
                                        </>
                                        : null
                                    }


                                    <div className="row">
                                        <div className="col-sm-3">
                                            <Form.Label className="erp-form-label">Return Status </Form.Label>
                                        </div>
                                        <div className='col'>
                                            <select id="cmb_return_status " className="form-select form-select-sm" value={cmb_return_status} onChange={(e) => { setReturnStatus(e.target.value); }} disabled >
                                                <option value="P">Pending</option>
                                                <option value="R">Received</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-sm-3">
                                            <Form.Label className="erp-form-label">Remark</Form.Label>
                                        </div>
                                        <div className="col">
                                            <Form.Control as="textarea" id='txt_remark' className="erp_input_field" value={txt_remark} onChange={e => setRemark(e.target.value)} optional='optional' maxLength={1000} />
                                            <MDTypography variant="button" id="error_txt_remark" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </form>
                    </div>
                    <hr />

                    <Accordion defaultActiveKey="0" activeKey={openIssueDetailsAccord ? '0' : null}>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header onClick={(e) => setOpenIssueDetailsAccord(!openIssueDetailsAccord)} className="erp-form-label-md"> Material Issue Details</Accordion.Header>
                            <Accordion.Body>

                                <div className="mt-10">
                                    {materialIssueDetailsData.length !== 0 ?
                                        <>
                                            {renderDetailsTable}
                                        </> :
                                        <div className='row  text-center'>
                                            <div className="col-12 ">
                                                <span className="erp_validation text-center" fontWeight="regular" color="error"> No Records Found... </span>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion >


                </>

                <div className="text-center mb-5">
                    <MDButton type="button" className="erp-gb-button" variant="button" fontWeight="regular" onClick={() => moveToListing()}>Back</MDButton>
                    <MDButton type="button" id="save-btn" className="erp-gb-button ms-2" variant="button" onClick={() => { FnAddReturnEntry() }}
                        fontWeight="regular">Save</MDButton>
                    <MDButton type="button" className="erp-gb-button ms-2" variant="button" id='clearbutton-id' onClick={FnClearFormFields} fontWeight="regular" disabled={keyForViewUpdate === 'view' || keyForViewUpdate === 'update' || keyForViewUpdate === 'approve' ? 'disabled' : ''}>Clear</MDButton>
                    <MDButton type="button" variant="button" onClick={() => { FnAddReturnEntry('MI') }} id="issue-btn"
                        className={`erp-gb-button ms-2 ${keyForViewUpdate === 'issue' ? 'display' : 'd-none'}`} fontWeight="regular" >Issue</MDButton>

                    <MDButton className={`erp-gb-button ms-2 ${keyForViewUpdate === 'view' ? cmb_return_status !== 'P' ? 'display' : 'd-none' : 'd-none'}`} variant="button" fontWeight="regular" id="printbtn_id"
                        onClick={() => { printIssueSlip(true); }} disabled={cmb_return_status !== "P" ? '' : 'disabled'} >Print<FiDownload className="erp-download-icon-btn" /></MDButton>
                </div >


                <ConfirmationModal close={() => closeConfirmationModal('Exit')} confirmation={() => closeConfirmationModal('Export&Exit')} show={[showConfirmationModal, succMsg, modalOrderDetails, messageForConfirmation]} />
                <SuccessModal handleCloseSuccessModal={() => closeConfirmationModal('Exit')} show={[showSuccessMsgModal, succMsg, modalOrderDetails]} />
                <ErrorModal handleCloseErrModal={() => handleCloseErrModal()} show={[showErrorMsgModal, errMsg, modalOrderDetails]} />

                <Modal size="lg" show={showAddRecIndentIssueModal} onHide={closeRecordAddIndentIssueModal} backdrop="static" keyboard={false} centered >
                    <Modal.Body className='erp_city_modal_body'>
                        <div className='row'>
                            <div className='col-12 align-self-end'><button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={closeRecordAddIndentIssueModal}></button></div>
                        </div>
                        {displayRecordIndentIssueComponent()}

                    </Modal.Body>
                </Modal >


            </DashboardLayout >
        </>
    )
}